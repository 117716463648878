
import { Fragment } from 'vue-frag'
import availabilityConstants from '~/store/availability/-constants'
import baseConstants from "~/store/base/-constants";
import { ProjectFocusMode } from '~/store/building/-constants'
import ClickOutside from 'vue-click-outside'
import projectConstants from "~/store/project/-constants";

export default {
  name: 'MoleculeExtraSidebarComponentsMobile',
  directives: {
    ClickOutside
  },
  components: {
    Fragment
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    isChooseViewOpen() {
      return this.$store.state.base.isChooseViewBuildingsOpen
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d?.getClientManager();
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    filteredBuildingData() {
      return this.$store.state.availability.project.find((b) => b.id === this.storeFilters.buildingId)
    },
    projectFocusMode() {
      return this.$store.state.building.projectFocusMode
    },
    isProjectFocus() {
      return this.projectFocusMode === ProjectFocusMode.DEFAULT
    },
    isBuildingFocus() {
      return this.projectFocusMode === ProjectFocusMode.BUILDING
    },
    isFloorFocus() {
      return this.projectFocusMode === ProjectFocusMode.FLOOR
    },
    isSpaceFocus() {
      return this.projectFocusMode === ProjectFocusMode.SPACE
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    activeProjectData() {
      return this.$store.state.project.projects.find((p) => p.slug === this.activeProject)
    },
    buildingData() {
      return this.$store.state.project.project.buildings.find((b) => b.id === this.storeFilters.buildingId)
    },
    buildingName() {
      return this.buildingData?.name || this.activeProjectData.name || this.$t('allBuildings');
    }
  },
  watch: {},
  mounted() {},
  methods: {
    handleClickOutside() {
      if (window.innerWidth <= 900) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_CHOOSE_VIEW_MENU))
      }
    },
    handleChooseView() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_CHOOSE_VIEW_MENU))
    },
    handleBackToMainView() {
      this.$store.dispatch(availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS));
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_SURFACE_FIELDS));
      this.clearSelectedSpace()
    },
    handleBackToBuildingView() {
      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
        {
          floorId: undefined,
          floorNo: undefined,
        }
      );
      this.clearSelectedSpace()
    },
    async clearSelectedSpace() {
      return this.$store.dispatch('building/viewSpace', {})
    }
  }
}
