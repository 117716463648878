

export default {
  name: 'MoleculeAvailabilitySpaceFilterResultHeader',
  computed: {
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    dynamicHeaderColumns() {
      const cols = this.projectFeatures?.listSpaceDetails || [];
      return cols.map((col) => ['lfa', 'nla'].includes(col) ? col.toUpperCase() : `${col.charAt(0).toUpperCase()}${col.slice(1)}` );
    }
  },
}
