
import { Fragment } from 'vue-frag'

export default {
  name: 'MoleculeAvailabilityFilterStatistics',
  components: {
    Fragment
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    dataType() {
      let type = 'project'
      if (this.storeFilters.buildingId) {
        type = 'building'
      }
      if (typeof this.storeFilters.floorNo !== 'undefined' || this.storeFilters.floorId) {
        type = 'floor'
      }
      return type
    },
    total() {
      return this.$store.state.availability.spaceStatisticsData.totalSpaces
    },
    available() {
      return this.$store.state.availability.spaceStatisticsData.availableSpaces
    },
    unavailable() {
      return this.$store.state.availability.spaceStatisticsData.unavailableSpaces
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    isLoadingData() {
      return this.$store.state.availability.isLoadingData
    },
  },
  watch: {},
  mounted() {},
  methods: {}
}
