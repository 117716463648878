
import { Fragment } from 'vue-frag'
import availabilityConstants from '~/store/availability/-constants'

export default {
  name: 'MoleculeAvailabilityProjectFilterResultsDisplay',
  components: {
    Fragment
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    spaceDisplayData() {
      return this.$store.state.availability.space
    },
    isLoadingData() {
      return this.$store.state.availability.isLoadingData
    },
    hasResults() {
      return this.spaceDisplayData.length > 0
    }
  },
  watch: {},
  mounted() {},
  methods: {}
}
