

import availabilityConstants from "~/store/availability/-constants";
import baseConstants from "~/store/base/-constants";
import projectConstants from "~/store/project/-constants";

export default {
  name: 'MoleculeChooseViewBuildings',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    storeFilters() {
      return this.$store.state.availability.filters
    },
    projects() {
      return this.$store.state.project.projects
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    activeProjectData() {
      return this.projects.find((p) => p.slug === this.activeProject)
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    buildingData() {
      return this.$store.state.project.project.buildings.find((b) => b.id === this.storeFilters.buildingId)
    },
    buildingName() {
      return this.activeProjectData.name || this.$t('allBuildings');
    },
    isChooseViewOpen() {
      return this.$store.state.base.isChooseViewBuildingsOpen
    },
  },
  watch: {},
  mounted() {},
  methods: {
    handleBackToMainView() {
      this.$store.dispatch(availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS));
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_SURFACE_FIELDS));
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_CHOOSE_VIEW_MENU))
    },
    handleBuildingClick(building) {
      if (this.storeFilters.buildingId === building.id) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_CHOOSE_VIEW_MENU))
        return
      }
      this.$store.dispatch('building/viewSpace', {})
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_CHOOSE_VIEW_MENU))
      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
        {
          buildingId: building.id,
          floorNo: undefined,
          floorId: undefined
        }
      )
    }
  }
}
