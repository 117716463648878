
import { Fragment } from 'vue-frag'
import availabilityConstants from '~/store/availability/-constants'

export default {
  name: 'MoleculeAvailabilityFilterResultsContainer',
  components: {
    Fragment
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    isLoadingData() {
      return this.$store.state.availability.isLoadingData
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    dataType() {
      let type = 'project'
      if (this.storeFilters.buildingId) {
        type = 'building'
      }
      if (typeof this.storeFilters.floorNo !== 'undefined' || this.storeFilters.floorId) {
        type = 'floor'
      }
      return type
    },
  },
  watch: {},
  mounted() {},
  methods: {}
}
