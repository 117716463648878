
import { Fragment } from 'vue-frag'
import availabilityConstants from '~/store/availability/-constants'
import { getElementHalfWidth } from '~/helpers/util';
import baseConstants from "~/store/base/-constants";

export default {
  name: 'MoleculeAvailabilitySpaceFilterResultBuilding',
  components: {
    Fragment
  },
  props: {
    displayData: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people']
    },
    isAvailable() {
      return this.displayData.available
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    shouldDisableAction() {
      return !this.isAvailable && !this.includeLeasedSpacesInResultsList
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spaceIsRequested() {
      return this.requestedSpaces.find((space) => space.id === this.displayData.id)
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed')
    },
    isEmbedV2Path() {
      return this.$route.path.includes('/embed/v2')
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    combinedSpace() {
      return this.displayData.combined_space
    },
    canCombineWithAnotherSpace() {
      return this.displayData.combined_space_id
    },
    linkedSpace() {
      return this.displayData.linked_space
    },
    hasLinkedSpace() {
      return this.displayData.linked_space_id
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    selectedSpace() {
      const space = { ...this.$store.state.building.space.spaceData }
      const building = this.buildings.find((b) => b.id === space.building_id)
      const floor = this.floors.find((f) => f.id === space.floor_id)
      space.building = building
      space.floor = floor
      return space
    },
    isSelectedSpace() {
      return this.displayData.id === this.selectedSpace.id;
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
  },
  watch: {},
  mounted() {
  },
  methods: {
    highlightSpace() {
      if (this.selectSpace.id === this.displayData.id) return;
      const { floor, space_code: spaceCode } = this.displayData
      const manager = this.engine3d.getClientManager()
      if (manager) {
        const spacesCode = spaceCode.split(';')
        manager.toggleHighlightSpace(
          floor.building.code.toLowerCase().trim(),
          floor.code.toLowerCase().trim(),
          spacesCode,
          this.displayData.available,
          true
        )
      }
    },
    unHighlightSpace() {
      if (this.selectSpace.id === this.displayData.id) return;
      const { floor } = this.displayData
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.toggleHighlightSpace(
          floor.building.code.toLowerCase().trim(),
          floor.code.toLowerCase().trim(),
          [],
          this.displayData.available,
          true
        )
      }
    },
    selectSpace() {
      const { floor, space_code: spaceCode, linked_space: linkedSpace } = this.displayData

      this.$store.dispatch('building/viewSpace', {
        space: spaceCode,
        building: floor.building.code,
        floor: floor.code,
        spaceData: this.displayData
      })

      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
        {
          buildingId: floor.building.id,
          floorId: floor.id,
          floorNo: floor.no,
        }
      )

      if (window.innerWidth <= 900) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_MOBILE_STATE))
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_FILTER))
      }

      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.showPinsByCategory('none')
        if (linkedSpace) {
          const linkedBuildingCodes = this.buildings.filter(e => e.id === linkedSpace.building_id);
          if (linkedBuildingCodes.length) {
            manager.hideBuildingCase(linkedBuildingCodes[0].code, floor.code);
          }
        }
      }
    },
    unselectSpace() {
      const { floor } = this.displayData
      const manager = this.engine3d.getClientManager()
      if (manager) {
        const elementHalfWidth = getElementHalfWidth('#organism-sidebar-project');
        const offsetValueX = (this.isMenuOpen && window.innerWidth > 900) ? -elementHalfWidth : 0;
        const offsetValueY = window.innerWidth <= 900 ? -50 : 0;
        const offsetTarget = { x: offsetValueX, y: offsetValueY };
        manager.cameraFocusTo(floor?.building?.code.toLowerCase(), floor?.code, offsetTarget);
      }
      this.$store.dispatch('building/viewSpace', {})

      if (window.innerWidth <= 900) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_MOBILE_STATE))
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_FILTER))
      }
    },
  }
}
